var site = site || {};

site.direction = site.direction || {};

(function ($) {
  Drupal.behaviors.basicCarouselFormatterV1 = {
    attach: function (context) {
      var $template = $('.js-basic-carousel-formatter-v1', context);

      // Loop through and init the carousels.
      // Carousels might contain variations, so dynamically change the settings before constructing slick
      $template.each(function () {
        var $self = $(this);
        var $carousel = $self.find('.js-basic-carousel');
        var $arrowsDiv = $self.find('.carousel-controls');
        var $mobileArrowsDiv = (function () {
          if ($self.hasClass('js-basic-carousel-formatter--content-block-arrows')) {
            return $self.find('.mantle-media-asset');
          }

          return $arrowsDiv;
        })();
        var $dotsDiv = $self.find('.carousel-dots');
        var basicSlide = '.js-basic-carousel__slide';
        var peeking = false;
        var defaultSlideDisplayInterval = 6000;
        var defaultSlideTransitionTime = 300;
        // autoplay settings
        var autoplay = $carousel.attr('data-slides-autoplay');
        var slideDisplayInterval = parseInt($carousel.data('slides-speed'));
        var slideTransitionTime = parseInt($carousel.data('slides-speedplay'));

        if (typeof autoplay === 'undefined') {
          autoplay = false;
        } else {
          autoplay = true;
        }
        if ($self.hasClass('js-basic-carousel-formatter--peeking')) {
          peeking = true;
        }
        if (isNaN(slideDisplayInterval)) {
          slideDisplayInterval = defaultSlideDisplayInterval;
        }
        if (isNaN(slideTransitionTime)) {
          slideTransitionTime = defaultSlideTransitionTime;
        }
        var settings = {
          rtl: site.direction.isRTL,
          arrows: true,
          appendArrows: $arrowsDiv,
          dots: true,
          appendDots: $dotsDiv,
          slide: basicSlide,
          fade: $self.data('fade') && $self.data('fade') === true,
          // prevent bug with dots not appearing active, disable infinite if scrolling more than one
          infinite: $self.data('slides') && $self.data('slides') > 1 ? false : true,
          slidesToShow: $self.data('slides'),
          slidesToScroll: 1,
          autoplay: autoplay,
          autoplaySpeed: slideDisplayInterval,
          speed: slideTransitionTime,
          adaptiveHeight: false,
          pauseOnFocus: true,
          onSetPosition: function (_slick) {
            _slick.$slider.trigger('afterSetPositionDots', _slick);
          },
          centerMode: $self.data('slides') === 1 && $self.hasClass('js-basic-carousel-formatter--pc-peeking') ? true : false,
          responsive: [
            {
              breakpoint: 1024,
              settings: {
                adaptiveHeight: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: peeking,
                appendArrows: $mobileArrowsDiv
              }
            }
          ]
        };

        if ($self.hasClass('slick-initialized')) {
          try {
            $self.slick('destroy');
          } catch (e) {
            console.log(e);
          }
        }
        $self.on('afterSetPositionDots', function (e, _slick) {
          var $mediaContentBlock = $carousel.find('.js-content-block-media');
          var position = 0;

          if ($mediaContentBlock.length && $self.hasClass('basic-carousel-formatter--overlap-style')) {
            position = $mediaContentBlock.position().top + $mediaContentBlock.height();
            $dotsDiv.css('top', position + 'px');
          }
        });
        // Init this carousel with our settings
        $carousel.slick(settings);
      });
    }
  };
})(jQuery);
